<template>
  <div>
    <router-view />
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>
<script>
import { getCurrentInstance, onMounted } from 'vue'

export default {
  setup() {
    const internalInstance = getCurrentInstance()
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    internalInstance.appContext.config.globalProperties.$Progress.start()
    onMounted(() => {
      internalInstance.appContext.config.globalProperties.$Progress.finish()
    })
    // setTimeout(() => {
    //   internalInstance.appContext.config.globalProperties.$Progress.finish()
    // }, 3500)
  },
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
