export const httpCodes = {
  success: 200,
  created: 201,
  successWithOutContent: 202,
  notModified: 304,
  malformedPetition: 400,
  notAuthorized: 401,
  notFound: 404,
  notAllowed: 405,
  responserconflict: 409,
  responserGone: 410,
  unsupportedMediaType: 415,
  unprocessableEntity: 422,
  toManyRequest: 429,
  internalServerError: 500,
}
