import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { userProfiles } from '@/utilities/userProfiles'

import DefaultLayout from '@/layouts/DefaultLayout'

const userRole = sessionStorage.getItem('userProfile')
  ? JSON.parse(sessionStorage.getItem('userProfile')).idProfiles
  : 0

const routes = [
  // login
  // {
  //   path: '/',
  //   redirect: {
  //     name: 'Login',
  //   },
  // },
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
    meta: {
      title: 'InfoAudit | Login',
    },
  },
  // rutas principales
  {
    path: '/home',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/home/dashboard',
    meta: {
      requiresAuth: true,
      label: 'Inicio',
    },
    children: [
      {
        path: '/home/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          title: 'InfoAudit | Dashboard',
          label: 'Dashboard',
          activeBreadCrumb: true,
        },
        // beforeEnter: (to, from) => {
        //   console.log(to)
        //   console.log(from)
        //   next()
        // },
      },
      //modulo de usuarios
      {
        path: '/users',
        redirect: '/users/all',
        meta: {
          label: 'Usuarios',
          onlyThisRoles: userProfiles.adminCoordSuper,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/users/new',
            name: 'newUser',
            meta: {
              label: 'Crear Nuevo Usuario',
              title: 'InfoAudit | Nuevo Usuario',
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ '@/views/users/newUser.vue'
              ),
          },
          {
            path: '/users/all',
            name: 'allUsers',
            meta: {
              label: 'Usuarios Registrados',
              title: 'InfoAudit | Usuarios Registrados',
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ '@/views/users/allUsers.vue'
              ),
          },
          {
            path: '/users/edit/:userId',
            name: 'editUser',
            props: true,
            meta: {
              label: 'Editar Usuario',
              title: 'InfoAudit | Editar Usuario',
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ '@/views/users/editUser.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (to.params.userId) {
                next()
              } else {
                next({ name: 'allUsers' })
              }
            },
          },
        ],
      },
      // rutas emails rebotados
      {
        path: '/bouncedmails',
        //redirect: '/bouncedmails/toassign',
        redirect: () => {
          if (userProfiles.allAnalists.includes(userRole)) {
            return { path: '/bouncedmails/toprocess' }
          }
          return { path: '/bouncedmails/toassign' }
        },
        meta: {
          label: 'E-Mails Rebotados',
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          // asignar empresas
          {
            path: '/bouncedmails/toassign',
            name: 'toAssign',
            meta: {
              label: 'Asignar empresas',
              title: 'InfoAudit | Asignar Empresas',
              onlyThisRoles: [
                userProfiles.adminUser,
                userProfiles.coordACT,
                userProfiles.superACT,
              ],
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "toAssign-free" */ '@/views/bouncedEmails/toAssign.vue'
              ),
          },
          // rebotes en proceso
          {
            path: '/bouncedmails/inprocess',
            name: 'inProcess',
            meta: {
              label: 'Empresas en Proceso',
              title: 'InfoAudit | Empresas en Proceso',
              onlyThisRoles: userProfiles.adminCoordSuper,
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "inProcess" */ '@/views/bouncedEmails/inProcess.vue'
              ),
          },
          // re asignar empresasa
          {
            path: '/bouncedmails/toreassign/:userId/:userName',
            name: 'toReAssign',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "reassign" */ '@/views/bouncedEmails/toReAssign.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (to.params.userId) {
                next()
              } else {
                next({ name: 'Home' })
              }
            },
            meta: {
              title: 'InfoAudit | Re Asignar Empresas',
              label: 'Re Asignar Empresas',
              onlyThisRoles: [
                userProfiles.adminUser,
                userProfiles.coordACT,
                userProfiles.superACT,
                userProfiles.coordATC,
                userProfiles.superATC,
              ],
              activeBreadCrumb: true,
            },
          },
          // por procesar
          {
            path: '/bouncedmails/toprocess',
            name: 'toProcess',
            meta: {
              label: 'Empresas por Procesar',
              title: 'InfoAudit | Empresas por Procesar',
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "toProcess" */ '@/views/bouncedEmails/toProcess.vue'
              ),
          },
        ],
      },
      //consulta
      {
        path: '/company',
        redirect: '/home/dashboard',
        meta: {
          label: 'Consultar',
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/company/consult/:bussinessId/:type',
            name: 'consultCompany',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "consult-company" */ '@/views/consultCompany.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (to.params.bussinessId && to.params.type) {
                next()
              } else {
                next({ name: 'Home' })
              }
            },
            meta: {
              title: 'InfoAudit | Consultar Empresa',
              label: 'Consultar Empresa',
              activeBreadCrumb: true,
            },
          },
        ],
      },
      //historial
      {
        path: '/history',
        redirect: '/history/analysts',
        meta: {
          label: 'Historial',
          onlyThisRoles: userProfiles.adminCoordSuper,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          //autocompletado para seleccionar usuario
          {
            path: '/history/analysts',
            name: 'analystsLogs',
            component: () =>
              import(
                /* webpackChunkName: "analysts-logs" */ '@/views/historyLogs/analystsLogs.vue'
              ),
            meta: {
              title: 'InfoAudit | Historial de Analistas',
              label: 'Historial de Analistas',
              activeBreadCrumb: true,
            },
          },
          //tabla con empresas del usuarion seleccionado
          {
            path: '/history/analysts/bussiness/:userId/:userName',
            name: 'bussinessAnalyst',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "bussiness-analyst" */ '@/views/historyLogs/bussinessAnalyst.vue'
              ),
            meta: {
              title: 'InfoAudit | Historial de Analistas',
              label: 'Empresas del Analista',
              activeBreadCrumb: true,
            },
          },
        ],
      },
      //reportes
      {
        path: '/reports',
        redirect: '/reports/bounces',
        meta: {
          label: 'Reportes',
          onlyThisRoles: userProfiles.adminCoordSuper,
        },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/reports/bounces',
            name: 'bouncesReports',
            meta: {
              label: 'Reporte de empresas con rebotes',
              title: 'InfoAudit | Reporte de rebotes',
              activeBreadCrumb: true,
            },
            component: () =>
              import(
                /* webpackChunkName: "bounce-report" */ '@/views/reports/bussinessBouncesReport.vue'
              ),
          },
        ],
      },
      // 404
      {
        path: '/:pathMatch(.*)*',
        name: 'pageNotFound',
        component: () =>
          import(/* webpackChunkName: "404" */ '@/views/404.vue'),
        meta: {
          title: 'InfoAudit | 404',
          label: 'Ooops!! página no encontrada',
          activeBreadCrumb: true,
        },
      },
      {
        path: '/faq',
        name: 'FAQ',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        meta: {
          requiresAuth: false,
          label: 'FAQ',
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'Infoguia'
  const publicPages = ['/']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = sessionStorage.getItem('logged')
  const requireRole = to.matched.some((record) => record.meta.onlyThisRoles)
  sessionStorage.removeItem('companiesSelected')
  if (authRequired && !loggedIn) {
    next({ name: 'Login' })
  } else {
    if (requireRole && !to.meta.onlyThisRoles.includes(userRole)) {
      next({ name: 'Dashboard' })
    } else {
      if (to.name === 'FAQ') {
        next({ name: 'Dashboard' })
        if (from.path !== '/') {
          window.open(process.env.VUE_APP_URL_FAQ, '_blank')
        }
      } else {
        next()
      }
    }
    //next()
  }
})

export default router
