<template>
  <div>
    <AppSidebar />
    <!-- modal aqui -->
    <CModal
      alignment="center"
      scrollable
      backdrop="static"
      :visible="isIdle"
      size="lg"
    >
      <CModalHeader :close-button="false" class="bg-info" style="color: white">
        <CModalTitle>Usuario Inactivo!!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol>
            <p>
              No se ha detectado actividad en más de <b>{{ inactiveTimer }}</b
              >.
            </p>
            <p>
              Si lo deseas puedes renovar tu sesi&oacute;n haciendo clic en el
              bot&oacute;n <b>Renovar</b>. De lo contrario puedes cerrar la
              sesi&oacute;n haciendo clic en el bot&oacute;n <b>Salir</b>.
            </p>
            <p>
              Esta modal se cerrar&aacute; en <b>{{ second }}</b> segundos y al
              hacerlo se cerrar&aacute; tu sesi&oacute;n en el sistema.
            </p>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="warning" :disabled="buttonDisable" @click="logOut">
          Salir
        </CButton>
        <CButton color="success" :disabled="buttonDisable" @click="refreshToken"
          >Renovar</CButton
        >
      </CModalFooter>
    </CModal>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <AppSubHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AppSubHeader from '@/components/AppSubHeader.vue'
import { watch, ref } from 'vue'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'DefaultLayout',
  setup() {
    // store de vuex
    const store = useStore()
    //constantes para el router
    const router = useRouter()
    const showModal = ref(false)
    const timeToClose = ref(30000)
    const clearTimer = ref(false)
    const buttonDisable = ref(false)
    const inactiveTimer = moment
      .duration(process.env.VUE_APP_EXPIRATION_TIMER)
      .humanize()
    watch(showModal, (currentValue) => {
      let timerId = 0
      if (currentValue) {
        timerId = setInterval(() => {
          timeToClose.value -= 1000
          //if (clearTimer.value) clearInterval(timerId)
          if (clearTimer.value) {
            clearInterval(timerId)
            showModal.value = false
            clearTimer.value = false
            timeToClose.value = 30000
          }
          if (timeToClose.value < 1) {
            clearInterval(timerId)
            logOut()
          }
        }, 1000)
      }
    })
    // metodo para cerrar la sesion
    function logOut() {
      buttonDisable.value = true
      store
        .dispatch('authModule/logout')
        .then(() => {
          clearTimer.value = true
          buttonDisable.value = true
          router.push({
            name: 'Login',
          })
        })
        .catch((error) => {
          console.log(error.response)
          clearTimer.value = true
          router.push({
            name: 'Login',
          })
        })
    }
    // metodo para refrescar el token
    function refreshToken() {
      buttonDisable.value = true
      clearTimer.value = true
      store
        .dispatch('authModule/refreshToken')
        .then(() => {
          buttonDisable.value = false
          clearTimer.value = true
        })
        .catch((error) => {
          console.log(error)
          clearTimer.value = true
        })
    }

    return {
      showModal,
      inactiveTimer,
      timeToClose,
      buttonDisable,
      logOut,
      refreshToken,
      clearTimer,
    }
  },
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSubHeader,
    CContainer,
  },
  created() {},
  computed: {
    // getUserTokens: function () {
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   return (this.userTokens = JSON.parse(
    //     sessionStorage.getItem('userTokens'),
    //   ))
    // },
    second() {
      return this.timeToClose / 1000
    },
    isIdle() {
      let idle = false
      if (this.$store.state.idleVue.isIdle && !this.showModal) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showModal = true
        idle = true
      } else if (!this.$store.state.idleVue.isIdle && this.showModal) {
        idle = true
      }
      return idle
    },
  },
}
</script>
