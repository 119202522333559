<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler
        class="ps-1"
        @click="$store.commit('coreuiStore/toggleSidebar')"
      >
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <div class="loginLogo">
          <img :src="imgLogo" style="width: 85%" alt="Infoguia" />
        </div>
      </CHeaderBrand>
      <!-- accesos rapidos -->
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CHeaderText style="font-weight: bold; color: black">
          Accesos r&aacute;pidos
        </CHeaderText>
        <!-- <CNavItem style="font-weight: bold">
          <div>Accesos r&aacute;pidos</div>
        </CNavItem> -->
        <CNavItem v-if="isAllowed">
          <CNavLink href="/users/new"><b>Crear Usuario</b></CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="/bouncedmails/toprocess">
            <b>Procesar Rebotes de E-Mail</b>
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import imgLogo from '@/assets/images/loginImgs/logo.png'
import { userProfiles } from '@/utilities/userProfiles'
export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  setup() {
    const userProfile = JSON.parse(sessionStorage.getItem('userProfile'))
    const isAllowed = userProfiles.adminCoordSuper.includes(
      userProfile.idProfiles,
    )
    return {
      imgLogo,
      //userProfile,
      isAllowed,
    }
  },
}
</script>
