import axiosInstance from './axiosInstance'
import { httpCodes } from '../utilities/httpCodes'

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('accessToken')
      if (token) {
        config.headers.common['Authorization'] = 'Bearer ' + token
      } else {
        delete config.headers.common['Authorization']
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  let isRefreshing = false
  let failedQueue = []
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    failedQueue = []
  }
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config
      if (originalConfig.url !== '/api/v1/login' && err.response) {
        if (
          err.response.status === httpCodes.notAuthorized &&
          !originalConfig._retry
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject })
            })
              .then((token) => {
                originalConfig.headers['Authorization'] = 'Bearer ' + token
                return axiosInstance(originalConfig)
              })
              .catch((err) => {
                return Promise.reject(err)
              })
          }
          isRefreshing = true
          originalConfig._retry = true
          return new Promise(function (resolve, reject) {
            store
              .dispatch('authModule/refreshToken')
              .then((response) => {
                const { access_token } = response
                axiosInstance.defaults.headers.common['Authorization'] =
                  'Bearer ' + access_token
                originalConfig.headers.Authorization = 'Bearer ' + access_token
                processQueue(null, access_token)
                resolve(axiosInstance(originalConfig))
              })
              .catch((err) => {
                console.log(err)
                processQueue(err, null)
                reject(err)
              })
              .finally(() => {
                isRefreshing = false
              })
          })
        }
      }
      return Promise.reject(err)
    },
  )
}
export default setup
