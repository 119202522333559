<template>
  <div class="subheader">
    <CRow>
      <CCol :md="12">
        <AppBreadcrumb />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import AppBreadcrumb from './AppBreadcrumb'
export default {
  name: 'AppSubHeader',
  components: {
    AppBreadcrumb,
  },
}
</script>
