<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="xl" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        <font-awesome-icon
          icon="fa-solid fa-id-card"
          style="margin-right: 5%"
        />
        Datos de la cuenta
      </CDropdownHeader>
      <CDropdownItem>
        <font-awesome-icon icon="fa-solid fa-user-tie" style="color: #39f" />
        {{ userInfo.name }}
      </CDropdownItem>
      <CDropdownItem>
        <font-awesome-icon icon="fa-solid fa-id-badge" style="color: #39f" />
        {{ profileInfo.name }}
      </CDropdownItem>
      <CDropdownItem>
        <font-awesome-icon icon="fa-solid fa-at" style="color: #2eb85c" />
        {{ userInfo.email }}
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        <font-awesome-icon
          icon="fa-solid fa-calendar-days"
          style="margin-right: 5%"
        />Fecha y hora actual
      </CDropdownHeader>
      <CDropdownItem>
        <font-awesome-icon icon="fa-solid fa-clock" style="color: #2c384aad" />
        {{ currentTime.dateTime }}
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem @click="logOut">
        <font-awesome-icon
          icon="fa-solid fa-power-off"
          style="color: #e55353"
        />
        Cerrar sesi&oacute;n
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/avatar.jpg'
import { reactive } from 'vue'
import moment from 'moment'
moment.locale('es-us')
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    const currentTime = reactive({ dateTime: null })
    return {
      avatar: avatar,
      // userInfo: {
      //   name: 'Christia López',
      //   email: 'developer2@infoguia.com.ve',
      //   profile: 'Administrador',
      // },
      userInfo: JSON.parse(sessionStorage.getItem('userData')),
      profileInfo: JSON.parse(sessionStorage.getItem('userProfile')),
      currentTime,
    }
  },
  created() {
    setInterval(() => this.updateCurrentTime(), 1 * 1000)
  },
  methods: {
    updateCurrentTime() {
      this.currentTime.dateTime = moment().format('DD/MM/y LTS')
    },
    logOut() {
      //let loader = this.$loading.show()
      this.$store
        .dispatch('authModule/logout')
        .then(() => {
          this.$router.push({
            name: 'Login',
          })
        })
        .catch((error) => {
          console.log(error.response)
          this.$router.push({
            name: 'Login',
          })
        })
        .finally(() => {
          //loader.hide()
        })
    },
  },
}
</script>
