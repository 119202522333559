//import basicos de vue
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//imports de coreui
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
//import de iconos de font awesome
import FontAwesomeIcon from '@/utilities/fontawesome'
// interceptores para axios
import axiosInterceptors from '@/utilities/axiosInterceptors'
axiosInterceptors(store)

// vue paginate next (https://github.com/cloudeep/vuejs-paginate-next)
import Paginate from 'vuejs-paginate-next'

//vue-multiselect (https://github.com/vueform/multiselect)
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
//bootstrap 5
//import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

const app = createApp(App)
//idle vue
import IdleVue from 'idle-vue'
//const eventsHub = new Vue()
app.use(IdleVue, {
  //eventEmitter: eventsHub,
  store,
  idleTime: process.env.VUE_APP_EXPIRATION_TIMER,
  //idleTime: 5000,
  startAtIdle: false,
})
// vue3-progressbar (https://github.com/aacassandra/vue3-progressbar)
import VueProgressBar from '@aacassandra/vue3-progressbar'
const optionsProgressBar = {
  color: '#97CA3D',
  failedColor: '#874b4b',
  thickness: '10px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
}
app.use(VueProgressBar, optionsProgressBar)
app.use(store)
app.use(router)
app.use(CoreuiVue)
// app.use(VueSweetalert2)
app.provide('icons', icons)
app.component('paginate', Paginate)
app.component('Multiselect', Multiselect)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
