import axiosInfoAudit from '@/utilities/axiosInstance'
//import userProfilesJson from '@/utilities/usersProfiles.json'
import tokenService from './token.service'
class AuthService {
  async login(user) {
    const response = await axiosInfoAudit.post('/api/v1/login', {
      email: user.username,
      password: user.password,
    })
    sessionStorage.setItem('userTokens', JSON.stringify(response.data))
    sessionStorage.setItem('accessToken', response.data.access_token)
    sessionStorage.setItem('userType', 'internal')
    sessionStorage.setItem('logged', true)
    return response.data
  }
  async refreshToken() {
    const response = await axiosInfoAudit.post('/api/v1/refreshtoken', {
      refresh_token: tokenService.getLocalRefreshToken(),
    })
    return response.data
  }
  async getDataUser() {
    const response = await axiosInfoAudit.get('api/v1/userloggedin')
    sessionStorage.setItem('userData', JSON.stringify(response.data.data))
    sessionStorage.setItem(
      'userProfile',
      JSON.stringify(response.data.data.profile),
    )
    return response.data.data
  }
  async logout() {
    const response = await axiosInfoAudit.post('/api/v1/logout')
    return response.data
  }
}

export default new AuthService()
