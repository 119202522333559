/* import the fontawesome core */
import { library, dom } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faEye,
  faEyeSlash,
  faUser,
  faUserLock,
  faEnvelope,
  faLock,
  faUserTie,
  faAt,
  faIdBadge,
  faIdCard,
  faClock,
  faCalendarDays,
  faPowerOff,
  faTachometerAlt,
  faDashboard,
  faUserPen,
  faUserXmark,
  faTriangleExclamation,
  faCircleInfo,
  faThumbsDown,
  faThumbsUp,
  faMagnifyingGlass,
  faCopy,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(
  faEye,
  faEyeSlash,
  faUser,
  faUserLock,
  faEnvelope,
  faLock,
  faUserTie,
  faAt,
  faIdBadge,
  faIdCard,
  faClock,
  faCalendarDays,
  faPowerOff,
  faTachometerAlt,
  faDashboard,
  faUserPen,
  faUserXmark,
  faTriangleExclamation,
  faCircleInfo,
  faThumbsDown,
  faThumbsUp,
  faMagnifyingGlass,
  faCopy,
  faXmark,
)
dom.watch()

export default FontAwesomeIcon
