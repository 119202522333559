<template>
  <CFooter>
    <div>
      <span style="font-weight: bold"> InfoAudit </span>
    </div>
    <div class="ms-auto">
      <CIcon :icon="iconFlag" size="xxl" />
    </div>
    <div class="ms-auto">
      <span class="me-1"
        >Desarrollado por
        <b
          ><a
            href="https://infoguia.com/"
            target="_blank"
            style="color: #666; text-decoration: none"
            >Infoguia</a
          ></b
        ></span
      >
      <span class="ms-1">{{ new Date().getFullYear() }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  setup() {
    const iconFlag = process.env.VUE_APP_ICON_FLAG
    return {
      iconFlag,
    }
  },
}
</script>
