const userProfiles = {
  all: 1,
  coordATC: 2, //coordinador atc
  superATC: 3, //supervisor atc
  analistATC: 4, //analista atc
  coordACT: 5, //coordinador actualizacion
  superACT: 6, //supervisor actualizacion
  analistACT: 7, //analista actualizacion
  ItTechnician: 8, //perfil de IT
  adminUser: 9, //administrador
  profilesATC: [2, 3, 4], //array con ids de perfiles para atc
  profilesACT: [5, 6, 7], //array con ids de perfiles para actualizacion
  allSupervisors: [3, 6], //array con ids de perfiles para supervisores
  allCoordinators: [2, 5], //array con ids de perfiles para coordinadores
  allAnalists: [4, 7, 8], //array con ids de perfiles para analistas
  adminCoordSuper: [2, 3, 5, 6, 9], //array con ids de perfiles para coordinadores, supervisores y admin
}

export { userProfiles }
