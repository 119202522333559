import { userProfiles } from '@/utilities/userProfiles'
export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/home/dashboard',
    icon: 'cil-speedometer',
    requiredRole: false,
  },
  {
    component: 'CNavItem',
    name: 'FAQ de InfoAudit',
    to: '/faq',
    icon: 'cil-book',
    requiredRole: false,
  },
  // administracion del sistema
  {
    component: 'CNavTitle',
    name: 'Administración del Sistema',
    requiredRole: userProfiles.adminCoordSuper,
  },
  {
    component: 'CNavGroup',
    name: 'Usuarios',
    icon: 'cil-group',
    requiredRole: userProfiles.adminCoordSuper,
    items: [
      {
        component: 'CNavItem',
        name: 'Nuevo usuario',
        to: '/users/new',
      },
      {
        component: 'CNavItem',
        name: 'Usuarios Registrados',
        to: '/users/all',
      },
    ],
  },
  //empresas gratuitas
  {
    component: 'CNavTitle',
    name: 'Empresas',
    requiredRole: false,
  },
  {
    component: 'CNavGroup',
    name: 'E-mail rebotados',
    icon: 'cil-envelope-open',
    requiredRole: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Asignar empresas',
        to: '/bouncedmails/toassign',
        requiredRole: [
          userProfiles.adminUser,
          userProfiles.coordACT,
          userProfiles.superACT,
        ],
      },
      {
        component: 'CNavItem',
        name: 'Empresas asignadas',
        to: '/bouncedmails/inprocess',
        requiredRole: userProfiles.adminCoordSuper,
      },
      {
        component: 'CNavItem',
        name: 'Procesar empresas',
        to: '/bouncedmails/toprocess',
        requiredRole: false,
      },
    ],
  },
  //historial
  {
    component: 'CNavTitle',
    name: 'Historial y reportes',
    requiredRole: userProfiles.adminCoordSuper,
  },
  {
    component: 'CNavItem',
    name: 'Historial de usuario',
    to: '/history/analysts',
    icon: 'cil-history',
    requiredRole: userProfiles.adminCoordSuper,
  },
  {
    component: 'CNavGroup',
    name: 'Reportes',
    icon: 'cil-chart-line',
    requiredRole: userProfiles.adminCoordSuper,
    items: [
      {
        component: 'CNavItem',
        name: 'Empresas con rebotes',
        to: '/reports/bounces',
      },
    ],
  },
]
